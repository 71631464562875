.leftSideBar {
  background-color: #160032;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  position: fixed;
  width: 100px;
  z-index: 1;
}

.toolbar {
  background-color: #cacdd4;
  height: 40px;
  position: sticky;
  text-align: center;
}

.mainContent {
  padding: 0;
  padding-left: 100px;
}

.mainContentBackground {
  min-height: 99%;
  margin: 16px;
}

/* Media queries for left bar size and corresponding main content padding */
@media screen and (min-width: 768px) {
  .leftSideBar {
    width: 200px;
  }
  .mainContent {
    padding-left: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .leftSideBar {
    width: 300px;
  }
  .mainContent {
    padding-left: 300px;
  }
}